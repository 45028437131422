import React from "react"
import SEO from "../components/common/seo"
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from 'gatsby'

const IntegrationsPage = ({pageContext, data}) => {
    const integrations = data.integrations.frontmatter;

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO title={integrations.title} />

            {/** 404
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14">

                {/** Decoration */}
                <div className="position-absolute top-right text-primary-dark mt-n12"
                     style={{ position: "absolute", zIndex: -99, left: "-70px", top: "700px", transform: "rotate(135deg)" }}>
                    <svg width="129" height="208" viewBox="0 0 129 208" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#decoration5clip0)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M142.812 201.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip1)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M142.812 149.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip2)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M142.812 97.281a6.47 6.47 0 1112.939.002 6.47 6.47 0 01-12.939-.002zm1.618 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.81-1.143-1.143 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.937 0zm1.617 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip3)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M38.813 149.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.938 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.469 6.469 0 1112.937.001 6.469 6.469 0 01-12.938-.001zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip4)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M38.813 97.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip5)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M38.813 45.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip6)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M90.813 175.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.344 1.143a6.469 6.469 0 1112.938.001 6.469 6.469 0 01-12.938-.001zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip7)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M90.813 123.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                  fill="#1f1f1f" />
                        </g>
                        <g clipPath="url(#decoration5clip8)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M90.813 71.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                  fill="#1f1f1f" />
                        </g>
                        <defs>
                            <clipPath id="decoration4clip0">
                                <path transform="matrix(0 -1 -1 0 155.75 207.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip1">
                                <path transform="rotate(-90 155.75 0)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip2">
                                <path transform="matrix(0 -1 -1 0 155.75 103.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip3">
                                <path transform="matrix(0 -1 -1 0 51.75 155.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip4">
                                <path transform="matrix(0 -1 -1 0 51.75 103.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip5">
                                <path transform="matrix(0 -1 -1 0 51.75 51.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip6">
                                <path transform="matrix(0 -1 -1 0 103.75 181.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip7">
                                <path transform="matrix(0 -1 -1 0 103.75 129.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                            <clipPath id="decoration4clip8">
                                <path transform="matrix(0 -1 -1 0 103.75 77.75)" fill="#1f1f1f" d="M0 0h51.75v51.75H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>


                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-12">

                            {/** Heading */}
                            <h1 align="center" className="display-1 font-weight-black text-vlackspacing">
                                {integrations.title}
                            </h1>

                            {/** Text */}
                            {/*<h3 align="center" className="font-size-xl text-black mb-0 font-weight-bold spacing">
                                Money 20/20 Tecalis exclusive content | Whitepaper
                            </h3>*/}

                            <br /><br />
                            {/** Button */}
                            <div align="center">
                                <a href={"/pdf/" + integrations.pdf} className="btn btn-primary mb-6 mr-3 spacing" target="_blank" style={{ width: "300px" }}>
                                    {integrations.button}
                                </a>
                            </div>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}


                {/** Decoration */}
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                     style={{ position: "absolute", top: "150px", right: "-20px" }}>
                    <svg width="185" height="186" viewBox="0 0 185 186" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" fill="#f43c51" />
                        <circle cx="22" cy="2" r="2" fill="#f43c51" />
                        <circle cx="42" cy="2" r="2" fill="#f43c51" />
                        <circle cx="62" cy="2" r="2" fill="#f43c51" />
                        <circle cx="82" cy="2" r="2" fill="#f43c51" />
                        <circle cx="102" cy="2" r="2" fill="#f43c51" />
                        <circle cx="122" cy="2" r="2" fill="#f43c51" />
                        <circle cx="142" cy="2" r="2" fill="#f43c51" />
                        <circle cx="162" cy="2" r="2" fill="#f43c51" />
                        <circle cx="182" cy="2" r="2" fill="#f43c51" />
                        <circle cx="2" cy="22" r="2" fill="#f43c51" />
                        <circle cx="22" cy="22" r="2" fill="#f43c51" />
                        <circle cx="42" cy="22" r="2" fill="#f43c51" />
                        <circle cx="62" cy="22" r="2" fill="#f43c51" />
                        <circle cx="82" cy="22" r="2" fill="#f43c51" />
                        <circle cx="102" cy="22" r="2" fill="#f43c51" />
                        <circle cx="122" cy="22" r="2" fill="#f43c51" />
                        <circle cx="142" cy="22" r="2" fill="#f43c51" />
                        <circle cx="162" cy="22" r="2" fill="#f43c51" />
                        <circle cx="182" cy="22" r="2" fill="#f43c51" />
                        <circle cx="2" cy="42" r="2" fill="#f43c51" />
                        <circle cx="22" cy="42" r="2" fill="#f43c51" />
                        <circle cx="42" cy="42" r="2" fill="#f43c51" />
                        <circle cx="62" cy="42" r="2" fill="#f43c51" />
                        <circle cx="82" cy="42" r="2" fill="#f43c51" />
                        <circle cx="102" cy="42" r="2" fill="#f43c51" />
                        <circle cx="122" cy="42" r="2" fill="#f43c51" />
                        <circle cx="142" cy="42" r="2" fill="#f43c51" />
                        <circle cx="162" cy="42" r="2" fill="#f43c51" />
                        <circle cx="182" cy="42" r="2" fill="#f43c51" />
                        <circle cx="2" cy="62" r="2" fill="#f43c51" />
                        <circle cx="22" cy="62" r="2" fill="#f43c51" />
                        <circle cx="42" cy="62" r="2" fill="#f43c51" />
                        <circle cx="62" cy="62" r="2" fill="#f43c51" />
                        <circle cx="82" cy="62" r="2" fill="#f43c51" />
                        <circle cx="102" cy="62" r="2" fill="#f43c51" />
                        <circle cx="122" cy="62" r="2" fill="#f43c51" />
                        <circle cx="142" cy="62" r="2" fill="#f43c51" />
                        <circle cx="162" cy="62" r="2" fill="#f43c51" />
                        <circle cx="182" cy="62" r="2" fill="#f43c51" />
                        <circle cx="2" cy="82" r="2" fill="#f43c51" />
                        <circle cx="22" cy="82" r="2" fill="#f43c51" />
                        <circle cx="42" cy="82" r="2" fill="#f43c51" />
                        <circle cx="62" cy="82" r="2" fill="#f43c51" />
                        <circle cx="82" cy="82" r="2" fill="#f43c51" />
                        <circle cx="102" cy="82" r="2" fill="#f43c51" />
                        <circle cx="122" cy="82" r="2" fill="#f43c51" />
                        <circle cx="142" cy="82" r="2" fill="#f43c51" />
                        <circle cx="162" cy="82" r="2" fill="#f43c51" />
                        <circle cx="182" cy="82" r="2" fill="#f43c51" />
                        <circle cx="2" cy="102" r="2" fill="#f43c51" />
                        <circle cx="22" cy="102" r="2" fill="#f43c51" />
                        <circle cx="42" cy="102" r="2" fill="#f43c51" />
                        <circle cx="62" cy="102" r="2" fill="#f43c51" />
                        <circle cx="82" cy="102" r="2" fill="#f43c51" />
                        <circle cx="102" cy="102" r="2" fill="#f43c51" />
                        <circle cx="122" cy="102" r="2" fill="#f43c51" />
                        <circle cx="142" cy="102" r="2" fill="#f43c51" />
                        <circle cx="162" cy="102" r="2" fill="#f43c51" />
                        <circle cx="182" cy="102" r="2" fill="#f43c51" />
                        <circle cx="2" cy="122" r="2" fill="#f43c51" />
                        <circle cx="22" cy="122" r="2" fill="#f43c51" />
                        <circle cx="42" cy="122" r="2" fill="#f43c51" />
                        <circle cx="62" cy="122" r="2" fill="#f43c51" />
                        <circle cx="82" cy="122" r="2" fill="#f43c51" />
                        <circle cx="102" cy="122" r="2" fill="#f43c51" />
                        <circle cx="122" cy="122" r="2" fill="#f43c51" />
                        <circle cx="142" cy="122" r="2" fill="#f43c51" />
                        <circle cx="162" cy="122" r="2" fill="#f43c51" />
                        <circle cx="182" cy="122" r="2" fill="#f43c51" />
                        <circle cx="2" cy="142" r="2" fill="#f43c51" />
                        <circle cx="22" cy="142" r="2" fill="#f43c51" />
                        <circle cx="42" cy="142" r="2" fill="#f43c51" />
                        <circle cx="62" cy="142" r="2" fill="#f43c51" />
                        <circle cx="82" cy="142" r="2" fill="#f43c51" />
                        <circle cx="102" cy="142" r="2" fill="#f43c51" />
                        <circle cx="122" cy="142" r="2" fill="#f43c51" />
                        <circle cx="142" cy="142" r="2" fill="#f43c51" />
                        <circle cx="162" cy="142" r="2" fill="#f43c51" />
                        <circle cx="182" cy="142" r="2" fill="#f43c51" />
                        <circle cx="2" cy="162" r="2" fill="#f43c51" />
                        <circle cx="22" cy="162" r="2" fill="#f43c51" />
                        <circle cx="42" cy="162" r="2" fill="#f43c51" />
                        <circle cx="62" cy="162" r="2" fill="#f43c51" />
                        <circle cx="82" cy="162" r="2" fill="#f43c51" />
                        <circle cx="102" cy="162" r="2" fill="#f43c51" />
                        <circle cx="122" cy="162" r="2" fill="#f43c51" />
                        <circle cx="142" cy="162" r="2" fill="#f43c51" />
                        <circle cx="162" cy="162" r="2" fill="#f43c51" />
                        <circle cx="182" cy="162" r="2" fill="#f43c51" />
                        <circle cx="2" cy="182" r="2" fill="#f43c51" />
                        <circle cx="22" cy="182" r="2" fill="#f43c51" />
                        <circle cx="42" cy="182" r="2" fill="#f43c51" />
                        <circle cx="62" cy="182" r="2" fill="#f43c51" />
                        <circle cx="82" cy="182" r="2" fill="#f43c51" />
                        <circle cx="102" cy="182" r="2" fill="#f43c51" />
                        <circle cx="122" cy="182" r="2" fill="#f43c51" />
                        <circle cx="142" cy="182" r="2" fill="#f43c51" />
                        <circle cx="162" cy="182" r="2" fill="#f43c51" />
                        <circle cx="182" cy="182" r="2" fill="#f43c51" />
                    </svg>
                </div>

            </section>

        </LayoutSecondary>
    )
}

export default IntegrationsPage

export const integrationsPageQuery = graphql
    `query($lang: String) {
        integrations: markdownRemark(
            frontmatter: {
                lang: { eq: $lang }
                name: { eq: "integrations" }
            }
        ) {
        frontmatter {
            title
            button
            pdf
        }
    }
        cookies: markdownRemark(
            frontmatter: {
                lang: { eq: $lang }
                name: { eq: "cookies" }
            }
        ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
